.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    background-color: #fff;
    border-bottom: 1px solid #dae4ff;
    margin-bottom: 0;
    .logo {
        width: 80px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(https://s1.mihaaru.com/connect/logos/thumbs/6cae986f9990838826ed20fa0417b7c8985139da.png);
    }
    color: #fff;
    .nav-link {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        li.search-input {
            position: relative;
            input {
                color: #000;
                width: 20rem;
            }
            .dropdown {
                position: absolute;
                top: 100%;
                color: #000;
                background: #ffff;
                width: 100%;
                border-radius: 7px;
                box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
                height: 20rem;
                z-index: 99;
                overflow: auto;
                padding: 1rem;
                ul{
                    list-style-type: circle;
                    padding-left: 0.5rem;
                }
                li{
                    color: #0b57d0;
                    font-size: 0.9rem;
                    font-weight: 500;
                    cursor: pointer;
                    margin: 0 0 0 1rem;
                    line-height: 1.4rem;
                    text-transform: lowercase;
                    &::first-letter{
                        text-transform: uppercase;
                    }
                }
            }
        }

        li {
            margin: 0 1rem;

            a,
            span {
                color: #0b57d0;
                display: flex;
                font-size: 0.9rem;
                font-weight: 500;
                cursor: pointer;
                // text-decoration: underline;
            }
        }
    }
}

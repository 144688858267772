.dashboard-container {
    display: flex;
    background-color: #f2f5fe;
    padding: 0.8rem 2rem;
    min-height: 92vh;
    gap: 1.5rem;

    .guest-list-container {
        flex: 1 1 60%;
        display: flex;
        flex-direction: column;
        .dashboard-wrapper {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
                color: #1B1464;
            }
            .sub-title {
                font-size: 0.8rem;
                color: #1B1464;
                margin-bottom: 2rem;
            }
        }
    }
    .guest-app-preview {
        flex: 1 1 40%;
    }
}

.guest-card {
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: contain;
    border-radius: 0.75rem;
    box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
    cursor: pointer;
    margin-bottom: 1rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    &.selected {
        // outline: 2px solid;
        // outline-color: #626262;
    }
    .guest-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        min-height: 3.5rem;
        .primary-name {
            font-size: 1rem;
            text-align: center;
            font-weight: 500;
            .name{
                background: #000;
                border: 2px solid #000;
                color: #fff;
                border-radius: 50%;
                padding: 6px 1px;
                background-color: #fff;
                color: #000;
                margin-right: 10px;
                display: inline-block;
                width: 2rem;
                height: 2rem;
                font-size: 11px;
                &.gold{
                    background-color: #FFD700;
                    border-color: #FFD700;


                }
                &.platinum{
                    background-color: rgb(229, 228, 226);
                    border-color: rgb(229, 228, 226);
                }
                &.titanium{
                    background-color: #807d7f;
                    border-color: #807d7f;
                }
            }
            small{
                color: #919191;
            }
        }
    }

    .btn-icon {
        border-radius: 50%;
        font-size: 0.85rem;
        height: 2rem;
        margin-left: 0.25rem;
        padding: 0.25rem;
        width: 2rem;
        background-color: #ffc800;
        border-color: #ffc800;
        color: #000;
    }
}
.guest-details {
    border-top: 1px solid #ececec;
    padding: 0.5rem 1rem 1rem;
}

.guest-app-preview {
    .mobile-view {
        background-image: url('../assets/iphone_frame.png');
        display: flex;
        align-items: center;
        background-size: 100% 100%;
        height: 590px;
        justify-content: center;
        margin: auto;
        position: relative;
        width: 300px;
        position: sticky;
        top: 0;
        iframe {
            border: none;
            border-radius: 26px;
            left: 19px;
            position: absolute;
            top: 18px;
        }
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.btn {
    background: #fff;
    border: 1px solid #cfe1e8;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 15px;
}
.btn-primary-alt {
    border-color: #ffc800;
    color: #ffc800;
}
.btn-primary {
    background-color: #1b1464;
    color: #fff;
}

label {
    font-size: 0.7rem !important;
    display: inline-block !important;
    margin-bottom: 0.5rem !important;
    color: #121b2b !important;
}
/* input,select,textarea {
    border-radius: 8px;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #000;
    outline: 0;
    letter-spacing: .5px;
    background: #fff;
    box-shadow: none;
    min-height: 42px;
} */
